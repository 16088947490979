import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { GetAsync, PutAsync } from "common/httpRequests";
import { BACKEND } from "constants/settings";
import * as Yup from "yup";
import useLocales from "hooks/useLocales";
import { Supplier } from "pages/accounts/accounts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField } from "components/hook-form";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import SupplierHeader from "pages/supplier_page/header/SupplierHeader";
import { StepContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { store } from "redux/store";
import {
  updateSupplierAction,
  updateSupplierOnboardingAction,
} from "redux/slices/supplier";

export default function Onboarding() {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [initialValues] = useState<Supplier>({
    email: "",
    name: "",
    registrationNumber: "",
    accountInfo: {
      accountHolderName: "",
      iban: "",
    },
  });
  const SupplierSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate("Email must be a valid email address"))
      .required(translate("Email is required")),
    name: Yup.string().required(translate("Name is required")),
    registrationNumber: Yup.string()
      .required(translate("registration_number_required"))
      .max(20, translate("registration_number_validation1"))
      .typeError(translate("registration_number_validation2")),
    accountInfo: Yup.object()
      .shape({
        accountHolderName: Yup.string().required(
          translate("account_holder_name_required")
        ),
        iban: Yup.string()
          .notRequired()
          .test(
            "iban_validation",
            translate("IBAN is not valid"),
            (value, context): boolean => {
              if (!value) {
                return true;
              }
              return /^[a-zA-Z0-9]+$/.test(value);
            }
          )
          .max(24, translate("IBAN cannot be more than 24 characters"))
          .required(translate("IBAN is required")),
      })
      .nullable(),
  });
  const fetchSupplierData = async () => {
    const currentSupplierId = window.location.pathname.split("/")[1];
    try {
      if (currentSupplierId) {
        const response = await GetAsync(
          `${BACKEND}/api/vendorservice/Suppliers/${currentSupplierId}`,
          {
            supplierId: currentSupplierId,
            code: localStorage.getItem("code"),
          }
        );
        if ([201, 200, 204].includes(response.status)) {
          reset(response.data);
        }
      }
    } catch (error) {}
  };
  const methods = useForm<Supplier>({
    resolver: yupResolver(SupplierSchema),
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = methods;
  useEffect(() => {
    fetchSupplierData();
  }, []);
  const navigate = useNavigate();
  const onSubmit = async (data: Supplier) => {
    const { accountInfo, email, name, registrationNumber } = data;
    const updatedData = {
      email,
      name,
      registrationNumber,
      id: window.location.pathname.split("/")[1],
      accountInfo:
        accountInfo?.accountHolderName || accountInfo?.iban
          ? accountInfo
          : null,
    };
    await PutAsync(
      `${BACKEND}/api/vendorservice/Suppliers/OnboardingSupplier`,
      {
        ...updatedData,
      },
      {
        supplierId: window.location.pathname.split("/")[1],
        code: localStorage.getItem("code"),
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(translate("Onboarding completed"));
          store.dispatch(
            updateSupplierOnboardingAction({
              accountInfo,
              email,
              found: true,
              id: window.location.pathname.split("/")[1],
              isDeleted: false,
              name,
              registrationNumber,
              isOnboardingDone: true,
            })
          );
          navigate(`/${window.location.pathname.split("/")[1]}/documents`);
        } else {
          enqueueSnackbar(translate(res.data.Detail), { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("supplier_screen.notCreated", { variant: "error" });
      });
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep == 0) {
      const partialSchema = SupplierSchema.pick([
        "name",
        "email",
        "registrationNumber",
      ]);
      partialSchema
        .validate(
          {
            name: methods.getValues("name"),
            email: methods.getValues("email"),
            registrationNumber: methods.getValues("registrationNumber"),
          },
          { abortEarly: false }
        )
        .then((valid) => {
          if (valid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            setError(error.path, {
              type: "manual",
              message: error.message,
            });
          });
        });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <SupplierHeader />
      <Container
        maxWidth={"md"}
        sx={{
          py: 4,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "primary.main",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          {translate("Onboarding")}
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {stepper.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                //   optional={
                //     index === steps.length - 1 ? (
                //       <Typography variant="caption">Last step</Typography>
                //     ) : null
                //   }
                >
                  {translate(step.label)}
                </StepLabel>
                <StepContent>
                  {step.content}
                  <Box sx={{ mb: 2 }}>
                    <Button
                      disabled={index === 0 || isSubmitting}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                      size="small"
                      variant="outlined"
                    >
                      {translate("Back")}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={
                        index === stepper.length - 1
                          ? handleSubmit(onSubmit)
                          : handleNext
                      }
                      sx={{ mt: 1, mr: 1 }}
                      disabled={isSubmitting}
                    >
                      {translate(
                        index === stepper.length - 1 ? "Finish" : "Continue"
                      )}
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </FormProvider>
      </Container>
    </>
  );
}

const Step1Form = () => {
  const { translate } = useLocales();
  return (
    <>
      <Typography variant="h5" paddingBottom={1}>
        {translate("Complete Your Profile")}
      </Typography>
      <Typography
        variant="subtitle1"
        paddingBottom={1}
        sx={{
          fontSize: "12px",
          fontWeight: "bold",
          color: "text.secondary",
        }}
      >
        {translate(
          "You're almost done! Complete any missing information to finish your setup."
        )}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RHFTextField name="name" label={translate("name")} />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField name="email" label={translate("Email")} disabled />
        </Grid>

        <Grid item xs={12}>
          <RHFTextField
            name="registrationNumber"
            label={translate("Company ID")}
          />
        </Grid>
      </Grid>
    </>
  );
};

const Step2Form = () => {
  const { translate } = useLocales();
  return (
    <>
      <Typography variant="h5" paddingBottom={1}>
        {translate("Verify Your Bank Details")}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "12px",
          fontWeight: "bold",
          color: "text.secondary",
        }}
        paddingBottom={1}
      >
        {translate(
          "Review your bank information and fill in any gaps for smooth transactions."
        )}
      </Typography>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <RHFTextField
              name="accountInfo.accountHolderName"
              label={`${translate("account_holder_name")}`}
            />
          </Grid>

          <Grid item xs={12}>
            <RHFTextField
              name="accountInfo.iban"
              label={`${translate("iban")}`}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const stepper = [
  {
    label: "Profile",
    content: <Step1Form />,
  },
  {
    label: "Bank Details",
    content: <Step2Form />,
  },
];
