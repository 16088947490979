import { FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  useFormContext,
  Control,
  FieldValues,
  Controller,
} from "react-hook-form";
import MyDatePicker from "components/pickers/DatePicker";

interface RHFDatePickerProps {
  name: string;
  label?: string | undefined;
}

const RHFDatePicker = (props: RHFDatePickerProps) => {
  const { name, label, ...other } = props;
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control as Control<FieldValues, any>}
        render={({
          field: { value, onChange, ref },
          fieldState: { error },
        }) => (
          <>
            <MyDatePicker
              sx={{
                fontSize: "12px",
                padding: "4px 26px 2px 10px",
              }}
              label={label}
              value={value}
              onChange={onChange}
              error={!!error}
              {...other}
            />
            {error && (
              <FormHelperText error sx={{ textAlign: "left", mt: -0.2 }}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default RHFDatePicker;
