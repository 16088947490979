import createAvatar from "utils/createAvatar";
import Avatar, { Props as AvatarProps } from "./Avatar";

export default function MyAvatar({
  user,
  ...other
}: AvatarProps & { user?: any }) {
  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.name}
      customColor={
        user?.photoURL ? "default" : createAvatar(user?.name).customColor
      }
      {...other}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  );
}
