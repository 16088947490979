// @mui
import { Theme } from "@mui/material/styles";
import {
  Box,
  SxProps,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import useLocales from "hooks/useLocales";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
} as const;

// ----------------------------------------------------------------------

type Props = {
  order?: "asc" | "desc";
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  loader?: boolean;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  loader,
  sx,
}: Props) {
  const { translate } = useLocales();
  return (
    <TableHead sx={{ borderRadius: 1, ...sx }}>
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            {loader ? (
              <Skeleton variant="rectangular" width={15} height={15} />
            ) : (
              <Checkbox
                size="small"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onSelectAllRows(event.target.checked)
                }
              />
            )}
          </TableCell>
        )}

        {headLabel.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell.width, minWidth: headCell.minWidth }}
          >
            {loader ? (
              <Skeleton width={90} />
            ) : onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => onSort(headCell.id)}
                sx={{ textTransform: "capitalize" }}
              >
                {translate(headCell.label)}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              translate(headCell.label)
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
