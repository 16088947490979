import { Grid, MenuItem, Typography, useTheme } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from "../../../components/hook-form";
import { Divider } from "@mui/material";
import useLocales from "hooks/useLocales";

const DocumentInformation = () => {
  const { translate: t } = useLocales();
  const { watch, setValue } = useFormContext();
  const theme = useTheme();

  const subSection = {
    pb: 2,
    pt: 1,
    mt: 2.5,
    pr: 2,
    ml: -1,
    borderRadius: "8px",
    background: theme.palette.background.neutral,
  };
  const documentClass = watch("documentClass");

  if (documentClass === "DeliveryNote") {
    setValue("deliveryNote", "");
  }

  return (
    <Grid container>
      <Grid container spacing={2} item xs={12} sx={subSection}>
        <Grid item xs={6}>
          <RHFSelect name={"documentClass"} label={t("document_class")}>
            <MenuItem value={"DeliveryNote"}>{t("deliveryNote")}</MenuItem>
            <MenuItem value={"Invoice"}>{t("invoice")}</MenuItem>
          </RHFSelect>
        </Grid>
        <Grid item xs={6}>
          <RHFTextField name={"documentNumber"} label={t("documentNumber")} />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} sx={subSection}>
        <Grid item xs={6}>
          <RHFDatePicker name={"documentDate"} label={t("documentDate")} />
        </Grid>
        <Grid item xs={6}>
          <RHFDatePicker name={"dueDate"} label={t("dueDate")} />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} sx={subSection}>
        <Grid item xs={6}>
          <RHFTextField name={"poNumber"} label={t("poNumber")} />
        </Grid>
        {documentClass === "Invoice" && (
          <Grid item xs={6}>
            <RHFTextField name={"deliveryNote"} label={t("deliveryNote")} />
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} spacing={2} sx={subSection}>
        <Grid item xs={6}>
          <RHFTextField name={"totalWithoutTax"} label={t("totalWithoutTax")} />
        </Grid>
        {/* <Grid item xs={6}>
          <RHFTextField name={"totalTax"} label={t("tax_amount_not_%")} />
        </Grid> */}
      </Grid>
      {/* <Grid item xs={6}>
        <RHFTextField name={"totalAmount"} label={t("totalAmount")} />
      </Grid> */}
    </Grid>
  );
};

export default DocumentInformation;
