import { BrowserRouter, Route, Routes } from "react-router-dom";
import EmailPageContainer from "../pages/email_send_page/skeleton/EmailPageContainer";
import AuthGuard from "guard/AuthGuard";
import Accounts from "pages/accounts/accounts";
import SupplierPageContainer from "pages/supplier_page/SupplierPageContainer";
import Onboarding from "pages/onboarding/Onboarding";
import GuestGuard from "guard/GuestGuard";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/:id"
          element={
            <GuestGuard>
              <EmailPageContainer />
            </GuestGuard>
          }
        />
        <Route
          path="/"
          element={
            <AuthGuard>
              <EmailPageContainer />
            </AuthGuard>
          }
        />
        <Route
          path="/:id/onboarding"
          element={
            <AuthGuard>
              <Onboarding />
            </AuthGuard>
          }
        />
        <Route
          path="/:id/documents"
          element={
            <AuthGuard>
              <SupplierPageContainer />
            </AuthGuard>
          }
        />
        <Route
          path="/:id/account"
          element={
            <AuthGuard>
              <Accounts />
            </AuthGuard>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
