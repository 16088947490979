import { Box, MenuItem, FormControl, SelectChangeEvent } from "@mui/material";
import NewTextField, { NewTextFormField } from "./form/TextField";
import { Select } from "@mui/material";
import { ReactNode } from "react";
// ----------------------------------------------------------------------

type Props = {
  state?: string;
  onChange?: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  options?: any;
  disabled?: boolean;
  label?: string;
  sx?: any;
};

export default function DropDown({
  state,
  onChange,
  options,
  disabled,
  label,
  sx,
}: Props) {
  return (
    <Box>
      <Select
        id="customized-textbox"
        labelId="demo-simple-select-outlined-label"
        input={<NewTextField label={label} sx={{ ...sx }} />}
        disabled={disabled}
        value={state}
        displayEmpty
        sx={{
          ...sx,
        }}
        onChange={onChange}
      >
        {options.map((optionItem) => (
          <MenuItem
            key={optionItem.enum}
            value={optionItem.enum}
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: "body2",
              textTransform: "capitalize",
            }}
          >
            {optionItem.value}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
export function MyDropDown({ state, onChange, options, disabled, sx }: Props) {
  return (
    <Box>
      <Select
        id="customized-textbox"
        labelId="demo-simple-select-outlined-label"
        input={<NewTextField label={"Status"} />}
        disabled={disabled}
        value={state}
        sx={{
          "& .MuiInputBase-input": {
            position: "relative",
            backgroundColor: "background.paper",
            padding: "3px 26px 2px 10px",
            borderRadius: "8px",
            height: "30px",
            ...sx,
          },
        }}
        onChange={onChange}
      >
        {options.map((optionItem) => (
          <MenuItem
            key={optionItem.enum}
            value={optionItem.enum}
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: "body2",
              textTransform: "capitalize",
            }}
          >
            {optionItem.value}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
