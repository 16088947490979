import { CalendarIcon } from "./../../assets/icon/calendar";
import { BootstrapInput } from "../form/TextField";
import { InputLabel } from "@mui/material";
import { useTheme } from "@mui/material";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "./../../assets/icon/chevron/left";
import ChevronRightIcon from "./../../assets/icon/chevron/right";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  PickerChangeHandlerContext,
  DateValidationError,
} from "@mui/x-date-pickers";
// ...

type DatePickerProps = {
  label?: string;
  value?: any;
  onChange(
    value: any,
    context: PickerChangeHandlerContext<DateValidationError>
  ): void;
  error?: boolean | undefined;
  disabled?: boolean;
  sx?: any;
};
const MyDatePicker = ({
  label,
  value,
  onChange,
  disabled,
  error,
  sx,
}: DatePickerProps) => {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "flex-start",
          p: 0,
          gap: "4px",
        }}
      >
        {label && (
          <InputLabel
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              color: theme.palette.action.active,
            }}
          >
            {label}
          </InputLabel>
        )}
        <DatePicker
          label={label}
          value={typeof value === "string" ? new Date(value) : value}
          disabled={disabled}
          onChange={onChange}
          slots={{
            openPickerIcon: CalendarIcon,
            openPickerButton: ({ ...props }) => (
              <IconButton
                size="small"
                sx={{
                  position: "absolute",
                  right: 18,
                  height: "30px",
                  width: "30px",
                  color: palette?.grey["400"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                  "&:disabled": {
                    color: palette?.grey["400"],
                    bgcolor: palette?.grey["300"],
                  },
                }}
                {...props}
              ></IconButton>
            ),
            leftArrowIcon: ({ ...props }) => (
              <ChevronLeftIcon height={20} width={20} {...props} />
            ),
            nextIconButton: ({ ...props }) => (
              <IconButton
                size="small"
                sx={{
                  height: "30px",
                  width: "30px",
                  color: palette?.grey["400"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                  "&:disabled": {
                    color: palette?.grey["400"],
                    bgcolor: palette?.grey["300"],
                  },
                }}
                {...props}
              ></IconButton>
            ),
            previousIconButton: ({ ...props }) => (
              <IconButton
                size="small"
                sx={{
                  height: "30px",
                  width: "30px",
                  color: palette?.grey["400"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                  "&:disabled": {
                    color: palette?.grey["400"],
                    bgcolor: palette?.grey["300"],
                  },
                }}
                {...props}
              ></IconButton>
            ),
            rightArrowIcon: ({ ...props }) => (
              <ChevronRightIcon height={20} width={20} {...props} />
            ),
            textField: TextField,
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default MyDatePicker;

const TextField = ({ ...params_ }) => {
  const theme = useTheme();
  let { margin, InputProps, inputProps, placeholder, error, sx, ...params } =
    params_;
  return (
    <BootstrapInput
      endAdornment={InputProps?.endAdornment}
      {...params}
      ref={InputProps.ref}
      placeholder={placeholder}
      fullWidth
      error={error}
      sx={{
        "& .MuiInputBase-input": {
          ...(error && {
            backgroundColor: theme.palette.error.light,
            borderColor: theme.palette.error.dark,
            color: theme.palette.error.dark,
            "&::placeholder": {
              opacity: 1,
              color: theme.palette.error.dark,
            },
            "&:focus": {
              borderColor: theme.palette.error.dark,
              boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
            },
          }),
        },
        ...sx,
      }}
    />
  );
};
