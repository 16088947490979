import { Box, Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableMoreMenu from "../../../../components/TableMoreMenu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import Label from "../../../../components/Label";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import { Tooltip } from "@mui/material";
import { FileExtension } from "../../../../components/FileExtension";
import DeleteIcon from "../../../../assets/icon/delete";
import { FONT_PRIMARY } from "theme/typography";
import EyeOpenIcon from "assets/icon/eye/On";
import useLocales from "hooks/useLocales";
import { fDateTime } from "./../../../../utils/formatDateTime";

const DocumentTableRow = ({
  row,
  handleOpenPayment,
  handleOpenERP,
  selected,
  onSelectRow,
  onDeleteSingleRow,
}: {
  row: any;
  handleOpenPayment: (row: any) => void;
  handleOpenERP: (row: any) => void;
  selected: any;
  onSelectRow: (event: React.MouseEvent<HTMLElement>) => void;
  onDeleteSingleRow: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { t }: any = useTranslation();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  const { translate } = useLocales();
  const fileExtension = row?.fileName?.split(".").pop();
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell size="small" padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} size="small" />
      </TableCell>
      <TableCell
        align="left"
        size="small"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box py={0.25} px={0.5}>
          <FileExtension
            ext={fileExtension}
            height="25px"
            width={"25px"}
            style={{
              color: theme.palette.text.greyLight,
            }}
          />
        </Box>
        <Box>
          <Tooltip title={row.fileName} placement="top">
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                textOverflow: "ellipsis",
                width: 300,
                cursor: "pointer",
                fontFamily: FONT_PRIMARY,
              }}
            >
              {row.fileName}
            </Typography>
          </Tooltip>
          {row.workFlowApprovalStatus != "NoApprovalRequired" && (
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ textOverflow: "ellipsis" }}
            >
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={
                  (row.workFlowApprovalStatus === "NeedApproval" &&
                    "warning") ||
                  (row.workFlowApprovalStatus === "Approved" && "success") ||
                  (row.workFlowApprovalStatus === "Rejected" && "error") ||
                  "default"
                }
                sx={{
                  textTransform: "capitalize",
                  height: "0.9rem",
                  fontWeight: 500,
                  fontSize: theme.spacing(1.2),
                  my: "auto",
                }}
              >
                {row.workFlowApprovalStatus === "NoApprovalRequired"
                  ? ""
                  : translate(row.workFlowApprovalStatus)}
              </Label>
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (row.status == "Default" && "warning") ||
            (row.status == "Extracted" && "success") ||
            (row.status == "Failed" && "error") ||
            (row.status == "Error" && "error") ||
            (row.status == "NeedReview" && "warning") ||
            (row.status == "Reviewed" && "success") ||
            (row.status == "Duplicate" && "info") ||
            "error"
          }
          sx={{ textTransform: "capitalize" }}
        >
          {t(row.status)}
        </Label>
      </TableCell>
      {/* <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={row.isIntegrated ? "success" : "error"}
          sx={{ textTransform: "capitalize" }}
        >
          {t(row.isIntegrated ? t("Yes") : t("No"))}
        </Label>
      </TableCell> */}
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            row.erpDetail?.status === "Approved"
              ? "success"
              : row.erpDetail?.status === "Rejected"
              ? "error"
              : "warning"
          }
          sx={{ textTransform: "capitalize" }}
        >
          {t(row.erpDetail?.status ?? "Pending")}
        </Label>
      </TableCell>
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            row.paymentDetail?.status === "UnPaid"
              ? "info"
              : row.paymentDetail?.status === "Paid"
              ? "success"
              : "warning"
          }
          sx={{ textTransform: "capitalize" }}
        >
          {t(row.paymentDetail?.status ?? "Pending")}
        </Label>
      </TableCell>
      <TableCell align="left">
        <Typography variant="subtitle2">
          {fDateTime(row.creationDateTime)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                disabled={!row.erpDetail}
                onClick={() => {
                  handleOpenERP(row);
                  handleCloseMenu();
                }}
              >
                <EyeOpenIcon color={"action"} />
                {t("View ERP details")}
              </MenuItem>
              <MenuItem
                disabled={!row.paymentDetail}
                onClick={() => {
                  handleOpenPayment(row);
                  handleCloseMenu();
                }}
              >
                <EyeOpenIcon color={"action"} />
                {t("View payment details")}
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  onDeleteSingleRow(e);
                  handleCloseMenu();
                }}
              >
                <DeleteIcon
                  disableDimension
                  customColor={theme.palette.action.active}
                />
                {t("Delete document")}
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default DocumentTableRow;
