import React from "react";

const LogoutIcon = ({
  width = 24,
  height = 24,
  color = "#657482",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8059 15.5469L21.3543 11.9985H9.09714M17.8059 8.45139L21.3543 11.9998M14 17V18C14 20.2091 12.2091 22 10 22H6C3.79086 22 2 20.2091 2 18L2 6C2 3.79086 3.79086 2 6 2L10 2C12.2091 2 14 3.79086 14 6V7"
        stroke="#657482"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
