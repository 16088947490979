import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import LanguagePopover from "./LanguagePopover";
import { useTranslation } from "react-i18next";
import NewDostLogo from "../../../assets/logo/newDostLogo.png";
import MyAvatar from "components/MyAvatar";
import { Divider, ListItem, useTheme } from "@mui/material";
import MenuPopover from "components/MenuPopover";
import LogoutIcon from "assets/icon/logout";
import UserIcon from "assets/icon/user";
import { useNavigate } from "react-router-dom";
import { dispatch } from "redux/store";
import { emailState } from "features/pageSlice";
import { useSelector } from "react-redux";
import { ListItemButton } from "@mui/material";

export default function SupplierHeader() {
  const supplier_ = useSelector((state: any) => state.supplier);
  const supplier = supplier_?.supplier;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem("code");
    localStorage.removeItem("date");
    dispatch(emailState());
    navigate(`/${window.location.pathname.split("/")[1]}`);
  }

  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        borderBottom: "1px solid grey.400",
      }}
    >
      <Box
        component="img"
        sx={{
          width: "90px",
          mr: 2,
          cursor: "pointer",
        }}
        alt="Dost logo"
        src={NewDostLogo}
        onClick={() =>
          navigate(`/${window.location.pathname.split("/")[1]}/documents`)
        }
      />
      <Typography
        component="div"
        sx={{
          flexGrow: 1,
          color: "#274560",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
      >
        {t("supplier")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mr: 2,
        }}
      >
        <LanguagePopover />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexDirection: "row",
          }}
        >
          <MyAvatar
            user={{ name: supplier?.name }}
            sx={{
              width: "32px",
              height: "32px",
              cursor: "pointer",
            }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          />

          <MenuPopover
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            sx={{
              mt: 1.5,
              ml: 0.75,
              width: 250,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.5,
              },
              bgcolor: "background.paper",
              p: 1,
              boxShadow:
                "0px 6px 12px -3px #424a530a, 0px 6px 18px 0px #424a531f", // "0px 2px 5px 0px rgba(0,0,0,0.12)",
              border: `1px solid #d0d7de`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: 1,
                alignItems: "center",
              }}
            >
              <MyAvatar
                user={{ name: supplier?.name }}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  {supplier?.name}
                </Typography>
                <Typography variant="subtitle2">{supplier?.email}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ListItemButton
                sx={{
                  pl: 1,
                  alignItems: "flex-start",
                  "&:hover": {
                    bgcolor: theme.palette.action.hover,
                  },
                  cursor: "pointer",
                  borderRadius: 0.5,
                }}
                disabled={!supplier?.isOnboardingDone}
                onClick={() =>
                  navigate(`/${window.location.pathname.split("/")[1]}/account`)
                }
              >
                <Box sx={{ mr: 1 }}>
                  <UserIcon height={18} width={18} />
                </Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.text.greyLight,
                    fontSize: 12,
                  }}
                >
                  {t("My profile")}
                </Typography>
              </ListItemButton>
              <Divider color={"#d0d7de"} />
              <ListItemButton
                sx={{
                  pl: 1,
                  alignItems: "flex-start",
                  "&:hover": {
                    bgcolor: theme.palette.action.hover,
                  },
                  cursor: "pointer",
                  borderRadius: 0.5,
                }}
                onClick={handleLogout}
                // key={notification?.id}
              >
                <Box sx={{ mr: 1, alignItems: "center" }}>
                  <LogoutIcon height={18} width={18} />
                </Box>
                <Typography
                  // noWrap
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.text.greyLight,
                    fontSize: 12,
                  }}
                >
                  {t("Logout")}
                </Typography>
              </ListItemButton>
            </Box>
          </MenuPopover>
        </Box>
      </Box>
    </Toolbar>
  );
}
