import {
  Box,
  Button,
  Container,
  Dialog,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Tabs,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SupplierHeader from "./header/SupplierHeader";
import UploadDocumentBox from "./upload_box/UploadDocumentBox";
import ColumnGroupingTable from "./components/table/Table";
import { DeleteAsync, GetAsync } from "../../common/httpRequests";
import { BACKEND } from "../../constants/settings";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { SupplierDocuments } from "../../@types/documents";
import { useSnackbar } from "notistack";
import ChevronDown from "../../assets/icon/chevron/ChevronDown";
import { UploadIcon } from "../../assets/icon/Upload";
import { DocumentIcon } from "../../assets/icon/Document";
import CreateDocument from "./createDocument";
import useInterval from "../../hooks/useInterval";
import ChevronRightIcon from "../../assets/icon/chevron/right";
import useTable from "hooks/useTable";
import useFilter from "./hooks/useFilter";
import { fDate } from "utils/formatDateTime";
import DeleteDocumentModal from "./components/modal/DeleteDocumentModal";
import useAuth from "hooks/useAuth";
import { Tab } from "@mui/material";
import axios from "axios";
import SearchFilters from "./Filters";

export interface Controller {
  page: number;
  rowsPerPage: number;
  erpStatus: string;
  paymentStatus: string;
}

interface StateProps {
  loading: boolean;
  documents: SupplierDocuments;
}
let source = axios.CancelToken.source();
export default function SupplierPageContainer() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isDeleteDocumentModalVisible, setIsDeleteDocumentModalVisible] =
    useState<boolean>(false);
  const [state, setState] = useState<StateProps>({
    loading: false,
    documents: {} as SupplierDocuments,
  });
  const [tabs, setTabs] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [loader, setLoader] = useState(false);
  const { filterState, setFilterState } = useFilter();
  const { allowed } = useAuth();
  const [controller, setController] = useState<Controller>({
    page: 0,
    rowsPerPage: 10,
    erpStatus: "",
    paymentStatus: "",
  });
  const [documentFilterFields, setDocumentFilterFields] = useState<string[]>(
    []
  );
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
  } = useTable({
    defaultOrderBy: "createDate",
    defaultCurrentPage: parseInt(controller.page.toString()) ?? 0,
    defaultRowsPerPage: parseInt(controller.rowsPerPage.toString()) ?? 10,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [docType, setDocType] = useState("");
  const [isUploadDocumentModalVisible, setIsUploadDocumentModalVisible] =
    useState(false);
  const [isCreateDocumentModalVisible, setIsCreateDocumentModalVisible] =
    useState(false);
  const [showUploadOptions, setShowUploadOptions] =
    useState<HTMLButtonElement | null>(null);

  const openUploadOptions = Boolean(showUploadOptions);

  const handleOpenUploadOptions = (event) =>
    setShowUploadOptions(event.currentTarget);

  const handleCloseUploadOptions = () => setShowUploadOptions(null);

  const getDocuments = async (
    page: number = controller?.page,
    rowsPerPage: number = controller?.rowsPerPage,
    filterState_: any = filterState
  ) => {
    const currentSupplierId = window.location.pathname.split("/")[1];
    GetAsync(
      `${BACKEND}/api/vendorservice/Suppliers/documents`,
      {
        supplierId: currentSupplierId,
        code: localStorage.getItem("code"),
      },
      {
        params: {
          page: controller?.page + 1,
          record: controller?.rowsPerPage,
          supplierId: currentSupplierId,
          documentName: filterState_.documentName || undefined,
          documentStatus: filterState_.invoiceStatus || undefined,
          documentClass: filterState_.documentClass || undefined,
          fromDate: filterState_.fromDate
            ? fDate(filterState_.fromDate, "yyyy-MM-dd")
            : undefined,
          toDate: filterState_.toDate
            ? fDate(filterState_.toDate, "yyyy-MM-dd")
            : undefined,
          approvalStatus: filterState_.approvalStatus || undefined,
          includeDeleted: filterState_.includeDeleted || undefined,
        },
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          setState({ ...state, loading: false, documents: res?.data });
          if (res?.data?.documentTypes && !tabs.length) {
            setTabs(res?.data?.documentTypes);
          }
        } else {
          enqueueSnackbar(t(res?.data?.Detail), { variant: "error" });
          setState({ ...state, loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const showModalMethod = () => setIsModalVisible(!isModalVisible);
  const [deleteLoader, setDeleteLoader] = useState(false);
  function renderUploadIfAllowed() {
    if (allowed) {
      if (isUploadDocumentModalVisible) {
        return (
          <UploadDocumentBox {...{ getDocuments, showModalMethod, docType }} />
        );
      } else if (isCreateDocumentModalVisible) {
        return <CreateDocument {...{ getDocuments, showModalMethod }} />;
      }
    } else {
      return (
        <Box
          borderRadius={"16px"}
          display="flex"
          alignItems="center"
          padding="24px"
        >
          {t("useDedicatedLink")}
        </Box>
      );
    }
  }

  useInterval(() => getDocuments(), 10000, []);

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    getDocuments();
  }, [controller]);

  const { documents, loading } = state;
  const handleDeleteSingleDocument = (docId: string) => {
    setSelected([docId]);
    setIsDeleteDocumentModalVisible(true);
  };
  const handleDeleteRow = async () => {
    setDeleteLoader(true);
    let fileIdsArr: any = [];
    const currentSupplierId = window.location.pathname.split("/")[1];
    selected.forEach((element) => fileIdsArr.push(element));
    const response = await DeleteAsync(
      `${BACKEND}/api/vendorservice/Suppliers/deleteDocuments?supplierId=${currentSupplierId}&${
        fileIdsArr
          ?.map((id: string, idx: number) => `documentIds[${idx}]=${id}`)
          .join("&") || ""
      }`,
      {},
      {
        supplierId: currentSupplierId,
        code: localStorage.getItem("code"),
      }
    );
    try {
      if (response.status === 200) {
        enqueueSnackbar(t(`deleted_document`));
        setDeleteLoader(false);
        setIsDeleteDocumentModalVisible(false);
        setSelected([]);
      }
    } catch (err) {
      enqueueSnackbar(t(`something_went_wrong`), {
        variant: "error",
      });
      setDeleteLoader(false);
      setIsDeleteDocumentModalVisible(false);
    }
    getDocuments(page, rowsPerPage, filterState);
  };
  const searchApi = async (filterState_) => {
    setLoader(true);
    source.cancel("cancel request");
    source = axios.CancelToken.source();
    setController({ ...controller, page: 0 });
    await getDocuments(1, controller.rowsPerPage, filterState_ ?? filterState);
    setLoader(false);
  };

  const optionsStatus = [
    { enum: "", value: `${t(`finance_screen.all`)}` },
    { enum: "1", value: `${t(`status.processed`)}` },
    { enum: "4", value: `${t(`status.need_review`)}` },
    { enum: "5", value: `${t(`status.reviewed`)}` },
    { enum: "6", value: `${t(`status.duplicate`)}` },
    { enum: "2", value: `${t(`status.failed`)}` },
  ];

  const optionsApprovalStatus = [
    { enum: "", value: `${t(`finance_screen.all`)}` },
    { enum: "0", value: `${t(`pending`)}` },
    { enum: "1", value: `${t(`approved`)}` },
    { enum: "2", value: `${t(`rejected`)}` },
  ];

  const optionsIntegrationStatus = [
    { enum: "", value: `${t(`All`)}` },
    { enum: "true", value: `${t(`Integrated`)}` },
    { enum: "false", value: `${t(`Not Integrated`)}` },
  ];
  return (
    <Box>
      <SupplierHeader />
      <Dialog
        open={isModalVisible}
        scroll="body"
        maxWidth="lg"
        fullWidth
        sx={{
          borderRadius: "8px",
        }}
      >
        {renderUploadIfAllowed()}
      </Dialog>
      <DeleteDocumentModal
        isShowModal={isDeleteDocumentModalVisible}
        showModalMethod={() => {
          setIsDeleteDocumentModalVisible(!isDeleteDocumentModalVisible);
          selected.length = 0;
        }}
        deleteDocument={() => handleDeleteRow()}
        selected={selected}
        totalCount={documents?.documents}
        deleteLoader={deleteLoader}
      />
      <Container
        maxWidth="lg"
        sx={{
          py: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
            variant="h3"
          >
            {t("Documents")}
          </Typography>

          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<ChevronDown customColor="white" />}
          >
            {t(`upload_documents`)}
          </Button>

          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              // onClick={() => {
              //   handleClose();
              // setIsModalVisible(!isModalVisible);
              // setIsCreateDocumentModalVisible(false);
              // setIsUploadDocumentModalVisible(true);

              // }}
              onClick={(event) => {
                handleOpenUploadOptions(event);
              }}
            >
              <UploadIcon width={20} height={20} />
              <Typography
                variant="subtitle1"
                color={theme.palette.action.active}
                sx={{ marginLeft: 0.5 }}
              >
                {t("upload_from_device")}
              </Typography>
              {showUploadOptions ? (
                <ChevronDown
                  customColor={theme.palette.action.active}
                  height={20}
                  width={20}
                />
              ) : (
                <ChevronRightIcon height={20} width={20} />
              )}
            </MenuItem>
            {documents.enableDocumentCreateThroughForm ? (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsModalVisible(!isModalVisible);
                  setIsUploadDocumentModalVisible(false);
                  setIsCreateDocumentModalVisible(true);
                }}
              >
                <DocumentIcon width={20} height={20} />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.action.active}
                  sx={{ marginLeft: 0.5 }}
                >
                  {t(`create_document`)}
                </Typography>
              </MenuItem>
            ) : null}
          </Menu>

          <Popover
            open={openUploadOptions}
            anchorEl={showUploadOptions}
            onClose={handleCloseUploadOptions}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuList dense>
              <MenuItem
                onClick={() => {
                  setDocType("Invoice");
                  handleClose();
                  setIsModalVisible(!isModalVisible);
                  setIsCreateDocumentModalVisible(false);
                  setIsUploadDocumentModalVisible(true);
                  handleCloseUploadOptions();
                }}
              >
                <UploadIcon
                  height={20}
                  width={20}
                  sx={{
                    height: "auto",
                    width: "auto",
                  }}
                />
                <ListItemText sx={{ ml: 1 }}>
                  {t("upload_invoice")}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDocType("DeliveryNote");
                  handleClose();
                  setIsModalVisible(!isModalVisible);
                  setIsCreateDocumentModalVisible(false);
                  setIsUploadDocumentModalVisible(true);
                  handleCloseUploadOptions();
                }}
              >
                <UploadIcon
                  height={20}
                  width={20}
                  sx={{
                    height: "auto",
                    width: "auto",
                  }}
                />
                <ListItemText sx={{ ml: 1 }}>
                  {t("upload_deliveryNote")}
                </ListItemText>
              </MenuItem>
            </MenuList>
          </Popover>
        </Box>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterState?.documentClass || tabs?.[0] || ""}
          onChange={(e, value) => {
            setFilterState({ ...filterState, documentClass: value });
            searchApi({ ...filterState, documentClass: value });
          }}
        >
          {tabs.map((tab) => (
            <Tab disableRipple key={tab} value={tab} label={t(tab)} />
          ))}
        </Tabs>
        <SearchFilters
          {...{
            filterState,
            setFilterState,
            optionsApprovalStatus,
            optionsIntegrationStatus,
            optionsStatus,
            loader,
            translate: t,
            searchApi,
            documentFilterFields,
            // documentFieldToggle,
          }}
        />
        <ColumnGroupingTable
          rows={documents?.documents}
          isLoading={loading}
          controller={controller}
          setController={setController}
          totalRecords={documents?.totalRecords}
          order={order}
          orderBy={orderBy}
          onSelectAllRows={onSelectAllRows}
          selected={selected}
          loader={loader}
          onSelectRow={onSelectRow}
          setIsDeleteDocumentModalVisible={setIsDeleteDocumentModalVisible}
          handleDeleteSingleDocument={handleDeleteSingleDocument}
        />
      </Container>
    </Box>
  );
}
