import { useState } from "react";

export type TFilterState = {
  documentClass: string;
  invoiceStatus: string | null;
  approvalStatus: string | null;
  includeDeleted: string | null;
  fromDate: Date | null;
  toDate: Date | null;
  documentName: string;
  fields?: Record<string, string> | null;
  paymentStatus: string[];
};
const useFilter = () => {
  const [filterState, setFilterState] = useState<TFilterState>({
    documentClass: "Invoice",
    invoiceStatus: "",
    approvalStatus: "",
    includeDeleted: "",
    fromDate: null,
    toDate: null,
    documentName: "",
    fields: null,
    paymentStatus: [],
  });

  return {
    filterState,
    setFilterState,
  };
};
export default useFilter;

// let getFields = (fields) => {
//   let fields_ = {};
//   if (fields.length === 0) return null;
//   fields.forEach((field) => {
//     fields_[field.fieldName] = field.value;
//   });
//   return fields_;
// };

export const getFieldsSearchParams = (searchParams: URLSearchParams) => {
  let fields: {
    fieldName: string;
    value: string;
  }[] = [];
  Array.from(searchParams.keys()).forEach((key) => {
    if (key.startsWith("fields")) {
      if (key.split(".").length > 1)
        fields.push({
          fieldName: key.split(".")[1],
          value: searchParams.get(key) as string,
        });
    }
  });
  return fields;
};

export const getPaymentStatusSearchParams = (searchParams: URLSearchParams) => {
  let paymentStatusFields: string[] = [];

  Array.from(searchParams.keys()).forEach((key) => {
    if (key.startsWith("paymentStatus")) {
      const paymentStatus = searchParams?.get(key)?.split(",");
      if (paymentStatus?.length && paymentStatus[0] !== "")
        paymentStatusFields = paymentStatus;
    }
  });
  return paymentStatusFields;
};
