import { GetAsync } from "common/httpRequests";
import { BACKEND } from "constants/settings";
import { createContext, useEffect, useState } from "react";
import { loginSupplierAction } from "redux/slices/supplier";
import { store } from "redux/store";

type AuthContextProps = {
  allowed: boolean | "";
  isLoading: boolean;
  fetchSupplierData: () => Promise<any>;
};

const AuthContext = createContext<AuthContextProps | null>(null);
const AuthProvider: React.FC<{ children }> = ({ children }) => {
  const [allowed, setAllowed] = useState<boolean | "">("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchSupplierData = async () => {
    const currentSupplierId = window.location.pathname.split("/")[1];
    setIsLoading(true);
    try {
      if (currentSupplierId) {
        const response = await GetAsync(
          `${BACKEND}/api/vendorservice/Suppliers/${currentSupplierId}`,
          {
            supplierId: currentSupplierId,
            code: localStorage.getItem("code"),
          }
        );
        if ([201, 200, 204].includes(response.status)) {
          store.dispatch(loginSupplierAction(response.data));
          setAllowed(true);
          return response.data;
        } else {
          setAllowed(false);
        }
      } else {
        setAllowed(false);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchAPIs = async () => {
      await fetchSupplierData();
    };
    fetchAPIs();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        allowed,
        isLoading,
        fetchSupplierData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
