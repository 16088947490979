import { createSlice } from "@reduxjs/toolkit";
// import { dispatch } from "redux/store";

type SupplierState = {
  supplier: {
    accountInfo: any;
    email: string;
    found: boolean;
    id: string;
    isDeleted: boolean;
    name: string;
    registrationNumber: string;
    isOnboardingDone: boolean;
  };
};

const initialState: SupplierState = {
  supplier: {
    accountInfo: null,
    email: "",
    found: false,
    id: "",
    isDeleted: false,
    name: "",
    registrationNumber: "",
    isOnboardingDone: false,
  },
};

const slice = createSlice({
  name: "Supplier",
  initialState,
  reducers: {
    loginSupplierAction(state, action) {
      let {
        accountInfo,
        email,
        found,
        id,
        isDeleted,
        name,
        registrationNumber,
        isOnboardingDone,
      } = action.payload;
      state.supplier = {
        accountInfo,
        email,
        found,
        id,
        isDeleted,
        name,
        registrationNumber,
        isOnboardingDone,
      };
    },
    logoutSupplierAction(state) {
      state.supplier = {
        accountInfo: null,
        email: "",
        found: false,
        id: "",
        isDeleted: false,
        name: "",
        registrationNumber: "",
        isOnboardingDone: false,
      };
    },
    updateSupplierAction(state, action) {
      let {
        accountInfo,
        email,
        found,
        id,
        isDeleted,
        name,
        registrationNumber,
      } = action.payload;
      state.supplier = {
        ...state.supplier,
        accountInfo,
        email,
        found,
        id,
        isDeleted,
        name,
        registrationNumber,
      };
    },
    updateSupplierOnboardingAction(state, action) {
      let {
        accountInfo,
        email,
        found,
        id,
        isDeleted,
        name,
        registrationNumber,
        isOnboardingDone,
      } = action.payload;
      state.supplier = {
        ...state.supplier,
        accountInfo,
        email,
        found,
        id,
        isDeleted,
        name,
        registrationNumber,
        isOnboardingDone,
      };
    },
  },
});

export default slice.reducer;

export const {
  loginSupplierAction,
  logoutSupplierAction,
  updateSupplierAction,
  updateSupplierOnboardingAction,
} = slice.actions;

// export const loginSupplier = (state: any) => {
//   return async () => {
//     dispatch(loginSupplierAction(state));
//   };
// };

// export const logoutSupplier = () => {
//   return async () => {
//     dispatch(logoutSupplierAction());
//   };
// };
