import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { GetAsync, PutAsync } from "common/httpRequests";
import { FormProvider, RHFTextField } from "components/hook-form";
import { BACKEND } from "constants/settings";
import useLocales from "hooks/useLocales";
import { useSnackbar } from "notistack";
import SupplierHeader from "pages/supplier_page/header/SupplierHeader";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { updateSupplierAction } from "redux/slices/supplier";
import { store } from "redux/store";
import * as Yup from "yup";

export type Supplier = {
  email: string;
  name: string;
  registrationNumber: string;
  accountInfo: {
    accountHolderName: string;
    iban: string;
  };
};

const Accounts = () => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [initialValues] = useState<Supplier>({
    email: "",
    name: "",
    registrationNumber: "",
    accountInfo: {
      accountHolderName: "",
      iban: "",
    },
  });
  const SupplierSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate("Email must be a valid email address"))
      .required(translate("Email is required")),
    name: Yup.string().required(translate("Name is required")),
    registrationNumber: Yup.string()
      .required(translate("registration_number_required"))
      .max(20, translate("registration_number_validation1"))
      .typeError(translate("registration_number_validation2")),
    accountInfo: Yup.object()
      .shape({
        accountHolderName: Yup.string().required(
          translate("account_holder_name_required")
        ),
        iban: Yup.string()
          .notRequired()
          .test(
            "iban_validation",
            translate("IBAN is not valid"),
            (value, context): boolean => {
              if (!value) {
                return true;
              }
              return /^[a-zA-Z0-9]+$/.test(value);
            }
          )
          .max(24, translate("IBAN cannot be more than 24 characters"))
          .required(translate("IBAN is required")),
      })
      .nullable(),
  });
  const fetchSupplierData = async () => {
    const currentSupplierId = window.location.pathname.split("/")[1];
    try {
      if (currentSupplierId) {
        const response = await GetAsync(
          `${BACKEND}/api/vendorservice/Suppliers/${currentSupplierId}`,
          {
            supplierId: currentSupplierId,
            code: localStorage.getItem("code"),
          }
        );
        if ([201, 200, 204].includes(response.status)) {
          reset(response.data);
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchSupplierData();
  }, []);

  const methods = useForm<Supplier>({
    resolver: yupResolver(SupplierSchema),
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: Supplier) => {
    const { accountInfo, email, name, registrationNumber } = data;
    const updatedData = {
      email,
      name,
      registrationNumber,
      id: window.location.pathname.split("/")[1],
      accountInfo:
        accountInfo?.accountHolderName || accountInfo?.iban
          ? accountInfo
          : null,
    };
    await PutAsync(
      `${BACKEND}/api/vendorservice/Suppliers/UpdateProfile`,
      {
        ...updatedData,
      },
      {
        supplierId: window.location.pathname.split("/")[1],
        code: localStorage.getItem("code"),
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(translate("Supplier Updated"));
          store.dispatch(
            updateSupplierAction({
              accountInfo,
              email,
              found: true,
              id: window.location.pathname.split("/")[1],
              isDeleted: false,
              name,
              registrationNumber,
            })
          );
        } else {
          enqueueSnackbar(translate(res.data.Detail), { variant: "error" });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar("supplier_screen.notCreated", { variant: "error" });
      });
  };
  return (
    <>
      <SupplierHeader />
      <Container
        maxWidth={"md"}
        sx={{
          py: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "primary.main",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            {translate("My Profile")}
          </Typography>
        </Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RHFTextField name="name" label={translate("name")} />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField name="email" label={translate("Email")} disabled />
            </Grid>

            <Grid item xs={6}>
              <RHFTextField
                name="registrationNumber"
                label={translate("Company ID")}
              />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <RHFTextField
                  name="accountInfo.accountHolderName"
                  label={`${translate("account_holder_name")}`}
                />
              </Grid>

              <Grid item xs={6}>
                <RHFTextField
                  name="accountInfo.iban"
                  label={`${translate("iban")}`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => navigate(-1)}
              sx={{
                width: "140px",
                height: "40px",
                fontSize: "14px",
                mr: 2,
              }}
              variant="outlined"
              size="small"
              disabled={isSubmitting}
            >
              {translate(`Go back`)}
            </Button>
            <LoadingButton
              sx={{
                width: "140px",
                height: "40px",
                fontSize: "14px",
              }}
              variant="contained"
              size="small"
              type="submit"
              loading={isSubmitting}
            >
              {translate(`Save`)}
            </LoadingButton>
          </Box>
        </FormProvider>
      </Container>
    </>
  );
};

export default Accounts;
